<template lang="pug">
  .mail-preference
    .mail-preference--header
    .mail-preference--content
      .mail-preference--content--image
        img(src="@/assets/ui/convert.png")
      .mail-preference--content--frequency
        .setting-card(v-for="item in frequecies" :key="item.id")
          .circle-empty(:class="{'circle-active': item.id === selectedTab}" @click="setSelectedTab(item.id)")
            div(v-if="item.id === selectedTab")
          span.settings-freqency {{item.name}}
          span.settings-freqency-recommended(v-if="item.isRecommended") (recommended)

      h3.
       Your email preference
       <span class="active-dark-text">for news emails</span>
       has been updated!
      p.subtitle This setting has no effect on other email types. You can modify your email settings {{' '}}
        <span class='active-text'>in your profile</span>.
</template>

<script>

export default {
  name: 'Settings',

  data: () => ({
    frequecies: [{
      id: 1,
      name: 'Daily'
    }, {
      id: 2,
      name: 'Weekly',
      isRecommended: true
    }, {
      id: 3,
      name: 'Two weeks'
    }, {
      id: 4,
      name: 'Monthly'
    },
    {
      id: 5,
      name: 'Never'
    }],
    selectedTab: 2
  }),

  methods: {
    setSelectedTab (id) {
      this.selectedTab = id
    }
  }
}
</script>

<style lang="scss" scoped>
.mail-preference {
  display:flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  background-color: white;
  &--header {
    position: absolute;
    height: 80px;
    width: 100%;
  }

  &--content {
    display:flex;
    align-items: center;
    flex-direction: column;
    margin-top: 150px;

    &--image{
      width: 230px;
      height: 200px;
    }

    &--frequency {
      display: flex;
      margin: 30px 0px;
    }

    h3 {
      margin-bottom: 1rem;
    }

    .active-text {
      font-size: 1.25rem;
    }

    .active-dark-text {
      font-size: 1.75rem;
    }
  }
}

   .setting-card {
       display: flex;
       flex-direction: column;
       align-items: center;
       width: 20%;
       padding: 0px 10px;
       min-width: 110px;

        &:last-child {
          .circle-empty {
          border: 3px solid #6D7587A1 !important;
        }
        .circle-active {
          border-color: transparent !important;
          background-color: #6D7587A1 !important;
        }
      }
   }

   .circle-empty {
      cursor: pointer;
      border: 3px solid #29A68770;
      border-radius:50%;
      height: 40px;
      width: 40px;
   }

   .circle-active {
        background-color: #29A687;
        display: flex;
        align-items: center;
        justify-content: center;
   }

    .settings-freqency {
      white-space: nowrap;
      padding-top: 10px;
      font-size: 1.1rem;
      font-weight: 600;
      color: #6D7587;
   }

   .settings-freqency-recommended {
      font-size: 1rem;
      color: #6D7587;
   }

</style>
